<template>
    <div class="login-modal-wrapper">
        <div class="login-modal">
            <div class="close">
                <img
                    src="@/assets/images/close-icon.svg"
                    alt="close"
                    @click="$emit('close')"
                />
            </div>
            <h1 class="title">Login</h1>
            <login-part
                :blueButton="true"
                :registration="true"
                @logined="$emit('logined')"
            />
        </div>
    </div>
</template>

<script>
import LoginPart from '@/components/login-part'

export default {
    name: 'login-modal',
    props: {
        registration: {
            type: Boolean,
            default: false
        }
    },
    components: {
        LoginPart
    },
    data: () => ({

    }),
}
</script>

<style lang="scss" scoped>
a:not(.md-button):hover {
    text-decoration: none;
}
.md-theme-default a:not(.md-button) {
    color: #FFFFFF;
}
.login-modal-wrapper{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-modal {
        width: 500px;
        background: #FFFFFF;
        padding: 30px 30px 50px 50px;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;

        img {
            cursor: pointer;
        }
    }

    .title {
        margin-bottom: 25px;
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
    }
}
</style>