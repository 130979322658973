<template>
    <div class="registration-page_wrapper">
        <div class="registration-page">
            <Breadcrumbs
                v-if="!isNotApplicationPage"
                :items="items"
                :formPage=true
            />
            <h2 class="registration-page_header">{{ $t('identity') }}</h2>
            <form action="" @submit.prevent="save">
                <div class="form-field">
                    <p>{{ $t('name') }} *</p>
                    <md-field>
                        <md-input v-model="form.lastName" :placeholder="$t('name')" required></md-input>
                    </md-field>
                    <div class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="tooltipLastNameActive = !tooltipLastNameActive" />
                        <md-tooltip :md-active.sync="tooltipLastNameActive" md-direction="right">
                            <p v-html="nameTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ $t('middleName') }} *</p>
                    <md-field>
                        <md-input :placeholder="$t('middleName')" required v-model="form.middleName"></md-input>
                    </md-field>
                    <div class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="tooltipMiddleNameActive = !tooltipMiddleNameActive" />
                        <md-tooltip :md-active.sync="tooltipMiddleNameActive" md-direction="right">
                            <p v-html="nameTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ $t('firstName') }} *</p>
                    <md-field>
                        <md-input v-model="form.firstName" :placeholder="$t('firstName')" required></md-input>
                    </md-field>
                    <div class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="tooltipFirstNameActive = !tooltipFirstNameActive" />
                        <md-tooltip :md-active.sync="tooltipFirstNameActive" md-direction="right">
                            <p v-html="nameTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ $t('gender') }} *</p>
                    <select name="" class="select-field" v-model="form.sexId" required>
                        <option
                            v-for="(gender,i) in genders"
                            :selected="form.sexId === gender.id"
                            :key="i"
                            :value="gender.id"
                        >
                            {{ gender.name }}
                        </option>
                    </select>
                </div>
                <div class="form-field">
                    <p>{{ $t('birthDate') }} *</p>
                    <md-datepicker id="birthDate" v-model="form.birthDate" class="birthDate" />
                </div>
                <div class="form-field">
                    <p>{{ $t('birthplace') }} *</p>
                    <md-field>
                        <md-input v-model="form.birthPlace" :placeholder="$t('birthplace')" required></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('nationality') }} *</p>
                    <select name="" class="select-field disabled-select" v-model="form.nationalityId" disabled="true">
                        <option
                            v-for="(nationality,i) in nationalities"
                            :selected="form.nationalityId === nationality.id"
                            :key="i"
                            :value="nationality.id"
                        >
                            {{ nationality.name }}
                        </option>
                    </select>
                </div>
              <div class="form-field">
              <p>Copie du passeport international *</p>
              <md-field :class="{'uploaded': Passport}">
                <md-file
                    @change="changeHandler('Passport', 'PassportStatusid')"
                    :required="files.Passport ? false : true"
                    :value="files.Passport"
                    accept="application/pdf"
                ></md-file>
              </md-field>
              <div class="document-status">
                <div v-html="svgApproved('PassportStatusid')" @click="selectDocumentStatus('PassportStatusid', 2)"></div>
                <div v-html="svgDeclined('PassportStatusid')" @click="selectDocumentStatus('PassportStatusid', 3)"></div>
              </div>
            </div>
                <div v-if="filesError.lastdegreecertifiedcopy" class="form-field">
                    <p></p>
                    <span class="file-error-msg">{{fileErrorMsg}}</span>
                </div>
                <div class="form-field">
                    <p>{{ $t('address1') }} *</p>
                    <md-field>
                        <md-input v-model="form.address1" :placeholder="$t('address1')" required></md-input>
                    </md-field>
                    <div class="tooltip-wrap">
                        <img src="@/assets/images/info.svg" alt="info" @click="tooltipAddressActive = !tooltipAddressActive" />
                        <md-tooltip :md-active.sync="tooltipAddressActive" md-direction="right">
                            <p v-html="addressTooltipMsg"></p>
                        </md-tooltip>
                    </div>
                </div>
                <div class="form-field">
                    <p>{{ $t('address2') }}</p>
                    <md-field>
                        <md-input v-model="form.address2" :placeholder="$t('address2')"></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('zipCode') }} *</p>
                    <md-field>
                        <md-input v-model="form.zipcode" :placeholder="$t('zipCode')" required></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('country') }} *</p>
                    <select name="" class="select-field" v-model="form.countryId" required>
                        <option
                            v-for="(country,i) in countries"
                            :selected="form.countryId === country.id"
                            :key="i"
                            :value="country.id"
                        >
                            {{ country.name }}
                        </option>
                    </select>
                </div>
                <div class="form-field">
                    <p>{{ $t('city') }}  *</p>
                    <select name="" class="select-field" v-model="form.cityId" required>
                        <option
                            v-for="(city,i) in cities"
                            :selected="form.cityId === city.id"
                            :key="i"
                            :value="city.id"
                        >
                            {{ city.name }}
                        </option>
                    </select>
                </div>
                <div class="form-field">
                    <p>{{ $t('telephone') }} *</p>
                    <select name="" class="select-field code-select" v-model="form.phoneNumberCodeId" required>
                        <option
                            v-for="code in phoneNumberCodes"
                            :selected="form.phoneNumberCodeId === code.id"
                            :key="code.id"
                            :value="code.id"
                        >
                            + {{ code.name }}
                        </option>
                    </select>
                    <md-field class="phone-input">
                        <md-input type="number" v-model="form.phoneNumber" :placeholder="$t('telephone')" required></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>Email *</p>
                    <md-field>
                        <md-input type="email" v-model="form.email" placeholder="Email" required></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <p>{{ $t('password') }} *</p>
                    <md-field>
                        <md-input type="password" v-model="form.password" :placeholder="$t('password')" :required="!edit"></md-input>
                    </md-field>
                  <div class="tooltip-wrap">
                    <img src="@/assets/images/info.svg" alt="info" @click="tooltipLastNameActive = !tooltipLastNameActive" />
                    <md-tooltip :md-active.sync="tooltipLastNameActive" md-direction="right">
                      <p v-html="passwordTooltipMsg"></p>
                    </md-tooltip>
                  </div>
                </div>
                <div class="form-field">
                    <p>{{ $t('confirmPassword') }} *</p>
                    <md-field>
                        <md-input type="password" v-model="form.confirmPassword" :placeholder="$t('confirmPassword')" :required="!edit"></md-input>
                    </md-field>
                </div>
                <div class="form-field">
                    <div class="buttons-wrap">
                        <md-button class="md-raised cancel" @click="cancel">
                            <span>Annuler</span>
                        </md-button>
                        <md-button class="next" type="submit">
                            <span>Sauvegarder</span>
                        </md-button>
                    </div>
                </div>
            </form>
            <Messages :messages="messages"></Messages>
            <Errors :errors="errors"></Errors>
        </div>
        <notify-existing-user
            v-if="showExistingUserModal"
            @close="showExistingUserModal = false"
            @showLoginModal="loginModalVisible = true"
        />
        <login-modal
            v-if="loginModalVisible"
            :registration="true"
            @close="closeModals"
            @logined="toProfilePage"
        />

    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import Errors from "@/components/Errors"
import Messages from "@/components/Messages"
import {errors} from "@/mixins/errors"
import {messages} from "@/mixins/messages"
import Breadcrumbs from '@/components/breadcrumbs'
import NotifyExistingUser from '@/components/notify-existing-user'
import LoginModal from '@/components/login-modal'

export default {
    name: 'Registration',
    data: () => ({
        files: {},
        filesError: {},
        Passport: null,
        edit: false,
        form: {
            id: null,
            middleName: '',
            firstName: '',
            lastName: '',
            sexId: '',
            birthDate: null,
            birthPlace: '',
            nationalityId: 150,
            address1: '',
            address2: '',
            zipcode: '',
            cityId: '',
            countryId: '',
            email: '',
            phoneNumberCodeId: '',
            phoneNumber: '',
            deactivated: false,
            password: '',
            confirmPassword: '',
        },
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'identity',
                href: '#',
            }
        ],
        showExistingUserModal: false,
        loginModalVisible: false,
        tooltipLastNameActive: false,
        tooltipMiddleNameActive: false,
        tooltipFirstNameActive: false,
        tooltipAddressActive: false,
        nameTooltipMsg: 'Merci d’indiquer vos nom et prénom inscrits <br /> sur votre passeport international',
        passwordTooltipMsg: 'Le mot de passe doit avoir au moins <br /> 10 charactères, une lettre majuscule, <br /> une lettre minuscule, un chiffre et un signe <br /> spécial',
        addressTooltipMsg: 'Merci de mettre votre adresse fixe (celle du <br /> domicile familial).',
    }),
    components: {
        Errors,
        Messages,
        Breadcrumbs,
        NotifyExistingUser,
        LoginModal,
    },
    mixins: [
        errors,
        messages,
    ],
    computed: {
        ...mapGetters(['simpleLists']),
        ...mapGetters('auth', ['userData']),
        ...mapGetters('applications', ['currentScholarshipTypeId', 'profileMetaInfo']),
        genders() {
            return this.simpleLists?.sex
        },
        nationalities() {
            return this.simpleLists?.nationality
        },
        phoneNumberCodes() {
            const codes = this.simpleLists?.phoneNumberCode;
            let unique;
            if(codes) {
                const sortedCodes = codes.sort(this.sortByLength);
                const key = 'name';
                unique = [...new Map(sortedCodes.map(item =>
                    [item[key], item])).values()];
            }
            return unique;
        },
        countries() {
            return this.simpleLists?.country
        },
        cities() {
            let city = [];
            if(this.form.countryId) {
                city = this.simpleLists?.city.filter(city => city.countryId === this.form.countryId)
            } else {
                city = this.simpleLists?.city
            }
            return city
        },
        isNotApplicationPage() {
            return this.$route.name.includes('not-application-identity')
        }
    },
      async mounted() {
//      const birthDate = document.querySelectorAll('#birthDate')[0].childNodes[2];
//      if (birthDate) {
//        birthDate.setAttribute('required', true)
//      }
//        if (!this.$route.params.studentId) {
//          this.$axios
//              .get(process.env.VUE_APP_API_URL + '/account/')
//              .then(({data}) => {
//                Object.assign(this.form, data)
//                this.edit = true
//                for (let key in this.form) {
//                  if (data[key !== "null"])
//                    this.form[key] = data[key]
//                }
//                this.Passport = data.passport
//                this.form.id = this.$route.params.studentId
//                this.form.birthDate = new Date(this.form.birthDate)
//                this.form.nationalityId = 150
//                this.formattedFiles()
//              })
//        }
      },
    methods: {
        ...mapActions('auth', ['registration', 'logout']),
        ...mapMutations('auth', ['setUserData']),
        toProfilePage() {
            // this.$router.push('/profile')
            if (this.$route.params.id) {
                this.$router.push(
                    {
                        name: 'student-profile',
                        params: {
                            id: this.$route.params.id,
                            studentId: this.userData.id,
                            profileId: this.$route.params.profileId || 0,
                        },
                    })
            }
        },
        async cancel() {
            if(this.currentScholarshipTypeId) {
                this.$router.push('/application')
            } else {
                await this.logout()
                this.$router.push('/')
            }
        },
        async save() {
//            this.clearErrors()
//            this.clearMessages()
//            this.form.nationalityId = 150
//            this.form.birthDate = this.$moment(this.form.birthDate).format('YYYY-MM-DD')
//            const data = await this.registration(this.form)
//            if (data.success) {
//                if (this.$route.params.id) {
//                    localStorage.setItem('bgfCandidateToken', data.token);
//                    localStorage.setItem('bgfCandidate', JSON.stringify(data));
//                    localStorage.setItem('bgfNewData', JSON.stringify(data));
//                    this.$axios.defaults.headers.Authorization = data.token;
//                    this.setUserData(data);
//                    this.$axios
//                    .post(process.env.VUE_APP_API_URL + '/account/edit', this.form)
//                    .then(({data}) => {
//                        const form = new FormData()
//                        form.append('id', data.id)
//                        form.append('Passport', this.Passport)
//                        this.$axios.post(process.env.VUE_APP_API_URL + '/account/UploadtFiles', form)
//                            .then(res => console.log(res))
//                            .catch(err => console.log(err))
//                    })
//                    localStorage.removeItem('bgfCandidate')
//                    this.$router.push(
//                        {
//                          name: 'student-profile',
//                          params: {
//                              id: this.$route.params.id,
//                              studentId: data.id,
//                              profileId: this.$route.params.profileId || 0,
//                        },
//                    })
//                } else {
//                    this.$notify({
//                        group: 'success-notify',
//                        type: 'success',
//                        title: this.$t('savedMessage'),
//                        text: '',
//                    });
//                }
//            } else {
//                this.setErrors([data.error])
//                if(!data.error) {
//                    this.setErrors(data.errors)
//                }
//                if (this.$route.name === 'registration' && data.error.includes('Cette adresse électronique existe déjà')) {
//                    this.showExistingUserModal = true
//                }
//            }
        },
        sortByLength(a, b) {
            const bandA = parseInt(a.name);
            const bandB = parseInt(b.name);

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        },
        closeModals() {
            this.loginModalVisible = false
            this.showExistingUserModal = false
        },
      changeHandler(field, status) {
        console.log(status)
          this.Passport = event.target.files[0];
          this.files[field] = !event.target.files[0] ? undefined :  event.target.files[0].name;
          this.filesError[field] = false
      },
      svgApproved(field) {
        return (this.form[field] === 2) ? `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#0045FF"/>
                  </svg>`
            :
            `<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.879 0.578766C20.5132 1.07185 20.6277 1.98568 20.1346 2.61988L8.82556 17.1653C8.5737 17.4892 8.19676 17.6918 7.78761 17.7229C7.37847 17.7541 6.97521 17.6109 6.67725 17.3288L0.531697 11.5106C-0.0516634 10.9583 -0.0768557 10.0377 0.475429 9.45435C1.02771 8.87099 1.94833 8.8458 2.53169 9.39808L7.51302 14.114L17.8379 0.834273C18.331 0.20008 19.2449 0.0856857 19.879 0.578766Z" fill="#CDD0D9"/>
                  </svg>`
      },
      svgDeclined(field) {
        return (this.form[field] === 3) ? `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#FF4646"/>
                  </svg>`
            :
            `<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9951 17.5362C16.4921 18.0332 17.298 18.0332 17.795 17.5362C18.292 17.0392 18.292 16.2333 17.795 15.7363L11.0586 8.99992L17.795 2.26352C18.292 1.76648 18.292 0.960639 17.795 0.463608C17.298 -0.0334225 16.4921 -0.0334225 15.9951 0.463608L9.25871 7.20002L2.52229 0.463594C2.02526 -0.0334377 1.21941 -0.0334377 0.722384 0.463594C0.225352 0.960624 0.225352 1.76647 0.722384 2.26351L7.45881 8.99992L0.722382 15.7363C0.225352 16.2333 0.225352 17.0392 0.722382 17.5362C1.21941 18.0333 2.02526 18.0333 2.52229 17.5362L9.25871 10.7998L15.9951 17.5362Z" fill="#CDD0D9"/>
                  </svg>`
      },
      selectDocumentStatus(field, value) {
        this.form[field] = value
      },
      formattedFiles() {
        this.files.Passport = this.Passport?.replace('Files/', ' ');
      },
    },
}

</script>

<style lang="scss" >
.registration-page {
  width: 700px;
  border-radius: 6px;
  &_header {
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 25px;
  }

  &_icon {
    position: absolute;
    left: 25px;
  }

  &_link {
    text-decoration: none;
    color: #0045FF;
    font-size: 18px;
    line-height: 32px;
  }

  .form-field {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    p {
      width: 160px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
    }

    .md-field {
      background: #FFFFFF;
      border: 1px solid #CDD0D9;
      border-radius: 4px;
      margin: 0 0 0 16px;
      padding: 0 0 0 24px;
      width: 60%;
      height: 48px;
      outline: none;
      font-size: 18px;
      line-height: 32px;


      .md-input {
        height: 48px;
      }
    }

    .phone-input {
      max-width: 330px;
      margin-left: 5px;
    }

    .md-field:after, .md-field:before {
      position: unset;
    }

    .disabled-select {
      background: #DCDCDC !important;
    }

    .select-field {
      width: 60%;
      background: #FFFFFF;
      border: 1px solid #CDD0D9;
      outline: none;
      border-radius: 4px;
      height: 48px;
      padding: 0 0 0 25px;
      margin-left: 16px;

      option {
        height: 48px;
      }
    }

    .code-select {
      padding: 0 0 0 5px;
      max-width: 85px;
    }
  }

  .buttons-wrap {
    display: flex;
    margin-left: 160px;
  }

  .cancel {
    width: 130px;
    height: 48px;
    margin-right: 25px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      text-transform: capitalize;
      color: #000000;
    }
  }

  .next {
    width: 130px;
    height: 48px;
    //margin-left: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    border-radius: 6px;
    background-color: #0045FF;
    color: #FFFFFF;

    img {
      margin-left: 13px;
    }
  }

  .tooltip-wrap {
    margin-left: 15px;
  }
  .md-datepicker {
    align-items: center;

    svg {
      fill: #0045FF !important;
    }
  }

  .md-field.md-theme-default > .md-icon:after {
    display: none;
  }

  .md-field .md-input-action {
    top: 8px !important;
  }

  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
    border-color: transparent !important;
  }

  .md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
    border-color: transparent !important;
  }
}
.registration-page_wrapper .form-field .md-field .md-file {
  flex-direction: row-reverse;
}
.registration-page_wrapper .document-status {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
}
.md-file {
  .md-file-icon {
    width: 80px;
    height: 48px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0;
    background-color: #0045FF;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 22px;
      fill: #FFFFFF;
      transform: rotate(45deg);
    }
  }

  .md-input {
    margin: 0 !important;
    height: 48px !important;
  }
}

</style>

<style lang="scss">
.registration-page {
    .md-datepicker {
        align-items: center;

        svg {
            fill: #0045FF !important;
        }
    }
    .md-field .md-input-action {
        top: 8px;
    }
}
.md-tooltip {
   color: #000000!important;
    background-color: #FFFFFF!important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    width: 400px!important;
    height: auto!important;
    font-size: 16px;
    line-height: 24px;
}

</style>